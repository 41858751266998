<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-loading="loading">
    <!-- 面包屑导航 -->
    <Crumbs>
      <el-breadcrumb-item slot="after">
        {{ val.list_s == 1 ? '添加' : '查看' }}外协</el-breadcrumb-item
      >
    </Crumbs>
    <!-- 内容主体 -->
    <div class="fullScreenMain" v-loading="loading">
      <!-- tabs页签 -->
      <el-tabs v-model="activeName" class="fullScreenMainHeader">
        <el-tab-pane label="外协信息"></el-tab-pane>
        <el-tab-pane label="附件上传"></el-tab-pane>
        <!-- 查看信息时采显示合作项目，添加时不显示 -->
        <el-tab-pane label="合作项目" v-if="val.list_s == 2"></el-tab-pane>
      </el-tabs>

      <!-- 外协信息 -->
      <div class="fullScreenMainCon">
        <!-- activeName == 0 -->
        <el-form
          class="column3 form_box"
          ref="outsourceInfoRef"
          label-width="7em"
          v-show="activeName == 0"
          :model="outsourceForm"
          :rules="outsourceRules"
        >
          <el-form-item label="公司名称" prop="name" ref="nameRef">
            <el-input
              :disabled="val.list_s == 2"
              v-model="outsourceForm.name"
              :placeholder="placeholderData"
            ></el-input>
          </el-form-item>
          <el-form-item label="法人" prop="legalPerson" ref="legalPersonRef">
            <el-input
              :disabled="val.list_s == 2"
              v-model="outsourceForm.legalPerson"
              :placeholder="placeholderData"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone" ref="phoneRef">
            <el-input
              :disabled="val.list_s == 2"
              v-model="outsourceForm.phone"
              :placeholder="placeholderData"
            ></el-input>
          </el-form-item>
          <el-form-item label="传真" prop="fax" ref="faxRef">
            <el-input
              :disabled="val.list_s == 2"
              v-model="outsourceForm.fax"
              :placeholder="placeholderData"
            ></el-input>
            <div>传真格式为: 020-87654321</div>
          </el-form-item>
          <el-form-item label="地址" prop="address" ref="addressRef">
            <el-input
              :disabled="val.list_s == 2"
              v-model="outsourceForm.address"
              :placeholder="placeholderData"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="mail" ref="mailRef">
            <el-input
              :disabled="val.list_s == 2"
              v-model="outsourceForm.mail"
              :placeholder="placeholderData"
            ></el-input>
          </el-form-item>
          <el-form-item label="开户银行" prop="depositBank" ref="depositBankRef">
            <el-input
              :disabled="val.list_s == 2"
              v-model="outsourceForm.depositBank"
              :placeholder="placeholderData"
            ></el-input>
          </el-form-item>
          <el-form-item label="银行账号" prop="accountBank" ref="accountBankRef">
            <el-input
              @input="inputFn('accountBank')"
              :disabled="val.list_s == 2"
              v-model="outsourceForm.accountBank"
              :placeholder="placeholderData"
            ></el-input>
          </el-form-item>
          <el-form-item
            style="width: 70%"
            label="公司资质"
            prop="qualification"
            ref="qualificationRef"
          >
            <el-input
              type="textarea"
              :disabled="val.list_s == 2"
              :autosize="{ minRows: 3, maxRows: 10 }"
              v-model="outsourceForm.qualification"
              :placeholder="placeholderData"
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- activeName == 1 -->
        <Annex
          :disabled="val.list_s == 2"
          :manageId="outsourceForm.id"
          :file_Data="file_Data"
          v-show="activeName == 1"
        ></Annex>
        <!-- activeName == 2 -->
        <el-table
          v-show="activeName == 2"
          :data="cooperativeList"
          style="width: 100%"
          border
          stripe
        >
          <el-table-column type="index" align="center" label="序号"></el-table-column>
          <el-table-column
            align="center"
            label="项目名称"
            :show-overflow-tooltip="false"
            prop="projectName"
          ></el-table-column>
          <el-table-column align="center" label="外协费用" prop="outsourceCost"></el-table-column>
          <el-table-column align="center" label="项目外协开始时间" prop="projectOutsourceStartDate">
            <template slot-scope="scope">
              <el-date-picker
                value-format="timestamp"
                type="date"
                disabled
                v-model="scope.row.projectOutsourceStartDate"
              ></el-date-picker
            ></template>
          </el-table-column>
          <el-table-column label="评价" align="center" prop="appraise"></el-table-column>
        </el-table>
        <!-- 按钮 -->
        <div class="fullScreenOperation">
          <el-button v-if="list_s == 1" type="success" :loading="loading" @click="handleSave">
            提交
          </el-button>
          <el-button v-if="list_s == 3" type="primary" :loading="loading" @click="handleUpdate">
            保存
          </el-button>
          <el-button type="info" @click="re">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'), //面包屑导航
    Annex: () => import('./Annex.vue'), //附件
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  inject: ['re'],
  data() {
    return {
      showEditDialog: false,
      loading: false,
      activeName: '0', //tabs页签选中项
      list_s: '0', //列表进入状态
      val: {},
      file_Data: [], //附件数组
      y_file_Data: [], //附件数组深拷贝
      cooperativeList: [], // 合作项目填写数据
      inputDisable: false, //控制输入框是否可用
      placeholderData: '请输入',
      //外协信息表单
      outsourceForm: {
        name: '', //* 公司名称
        legalPerson: '', //* 法人
        phone: '', //* 手机号
        fax: '', //* 传真
        address: '', //* 地址
        mail: '', //* 邮箱
        depositBank: '', //* 开户银行
        accountBank: '', //* 银行账号
      },

      // 表单验证对象
      outsourceRules: {
        name: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        legalPerson: [{ required: true, message: '请输入法人名称', trigger: 'blur' }],
        phone: [
          {
            required: true,
            validator: this.partyPhone,
            trigger: ['blur', 'change'],
          },
        ],
        // 移除必填项
        // fax: [
        //   {
        //     validator: this.partyFax,
        //     trigger: ['blur', 'change'],
        //   },
        // ],
        // mail: [
        //   {
        //     validator: this.partyMail,
        //     trigger: ['blur', 'change'],
        //   },
        // ],
        // accountBank: [
        //   {
        //     validator: this.partyAccountBank,
        //     trigger: ['blur', 'change'],
        //   },
        // ],
      },
    }
  },
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.val = newVal
        this.val.initiator = this.userInfo.userName
        this.val.initiatorId = this.userInfo.staffId
        this.list_s = this.val.list_s
        this.activeName = '0'
        if (newVal.id) {
          this.inputDisable = true
          // 点击的是详情，获取当前该外协信息
          this.clearInput()
          this.getData()
        } else {
          this.inputDisable = false
          // 点击的是新增
          this.outsourceForm = newVal
          this.file_Data = []
          this.placeholderData = '请输入'
        }
      },
    },
  },
  methods: {
    // 获取当前外协详情
    getData() {
      this.editDialogLoading = true
      this.placeholderData = ''
      this.$api.outsource
        .getDetailOutsourceData(this.val.id)
        .then(res => {
          this.editDialogLoading = false
          console.log(res)
          if (res.code !== 200) return this.$message.error('请求失败')
          let arr = []
          if (res.data.outsourceCompanyFileList.length > 0) {
            // 有附件
            res.data.outsourceCompanyFileList.forEach(item => {
              item.attachmentName = item.fileName
              item.attachmentPath = item.filePath
              item.remark = item.fileContent
              arr.push(item)
            })
          }
          this.file_Data = arr
          this.y_file_Data = arr.deepClone()
          this.outsourceForm = res.data
          this.cooperativeList = res.data.outsourceProjectList
        })
        .catch(err => {
          this.editDialogLoading = false
          console.log(err)
        })
    },

    // 新增页面点击提交
    handleSave() {
      // 1.表单校验
      this.$refs.outsourceInfoRef.validate(valid => {
        if (valid) {
          // 2. 判断节流阀状态
          if (
            this.file_Data.some(value => {
              return value.loading
            })
          ) {
            //通过some方法找value中loading存在，若存在说明正在加载
            this.$message.warning('文件上传中，请稍候重试!')
            return
          }
          this.file_Data.forEach(item => {
            console.log(item)
            item.fileContent = item.remark
          })
          console.log(this.file_Data)
          // 3.整理数据对象
          let cond = {
            ...this.outsourceForm,
            outsourceCompanyFileList: this.file_Data.deepClone(),
          }
          // 4.发送请求
          this.editDialogLoading = true
          this.$api.outsource
            .addOutsourceData(cond)
            .then(res => {
              this.editDialogLoading = false
              if (res.code !== 200) return this.$message.error('数据提交失败')
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.re()
            })
            .catch(err => {
              this.editDialogLoading = false
              console.log(err)
            })
        }
      })
    },

    // 移除表单验证结果
    clearInput() {
      this.$refs.outsourceInfoRef.clearValidate()
    },

    // 处理编辑操作
    handleUpdate() {
      // 1.表单校验
      this.$refs.outsourceInfoRef.validate(valid => {
        if (valid) {
          // 2. 判断节流阀状态
          if (
            this.file_Data.some(value => {
              return value.loading
            })
          ) {
            //通过some方法找value中loading存在，若存在说明正在加载
            this.$message.warning('文件上传中，请稍候重试!')
            return
          }
          this.file_Data.forEach(item => {
            console.log(item)
            item.fileContent = item.remark
          })
          console.log(this.file_Data)
          // 3.整理数据对象
          let cond = {
            ...this.outsourceForm,
            outsourceCompanyFileList: this.file_Data.deepClone(),
          }

          this.editDialogLoading = true
          this.$api.outsource
            .editOutsourceData(cond)
            .then(res => {
              this.editDialogLoading = false
              if (res.code !== 200) return this.$message.error('数据提交失败')
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.re()
            })
            .catch(err => {
              this.editDialogLoading = false
              console.log(err)
            })
        }
      })
    },
    partyPhone(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入联系人手机号或座机号'))
      } else {
        const reg =
          /^(((\d{3,4}-)?[0-9]{5,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/ //手机号正则表达式或座机号

        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号或座机号'))
        }
      }
    },
    partyFax(rule, value, callback) {
      const reg = /^(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/ //传真正则表达式

      if (reg.test(value) || '' === value || undefined === value || null === value) {
        callback()
      } else {
        return callback(new Error('请输入正确的传真号'))
      }
    },
    partyMail(rule, value, callback) {
      const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/ //邮箱正则表达式

      if (reg.test(value) || '' === value || undefined === value || null === value) {
        callback()
      } else {
        return callback(new Error('请输入正确的邮箱地址'))
      }
    },
    partyAccountBank(rule, value, callback) {
      // const reg = /^\d{7,30}$/; //通用银行账号正则表达式
      // value = value.replace(/\s/g, "");
      // if (reg.test(value) || "" === value || undefined === value || null === value) {
      //   callback();
      // } else {
      //   return callback(new Error("请输入正确的银行账号"));
      // }
      if (!value) {
        callback(new Error('请输入银行账号'))
      } else {
        const reg = /^\d{7,30}$/ //通用银行账号正则表达式
        value = value.replace(/\s/g, '')
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的银行账号'))
        }
      }
    },
    inputFn(key) {
      this.outsourceForm[key] = this.outsourceForm[key].replace(/\s*/g, '')
      var result = []
      for (var i = 0; i < this.outsourceForm[key].length; i++) {
        if (i % 4 == 0 && i != 0) {
          result.push(' ' + this.outsourceForm[key].charAt(i))
        } else {
          result.push(this.outsourceForm[key].charAt(i))
        }
      }
      this.outsourceForm[key] = result.join('')
    },
  },
}
</script>

<style scoped lang="scss">
// @import "@/styles/edit.scss";

@import '@/styles/config.scss';
.addButton {
  margin-bottom: 10px;
}

table {
  width: 100%;
}
td {
  width: 25vh;
}
</style>
